import { createSelector } from 'reselect'

const prStateSelector = state => state?.customer

export const customerDataSelector = createSelector(
  prStateSelector,
  state => state?.data
)

export const customerRecommendations = createSelector(
    prStateSelector,
    state => state?.recommendations
)

export const customerRecommendedArticles = createSelector(
    prStateSelector,
    state => state?.recommended_articles
)

export const customerRecommendedTraining = createSelector(
    prStateSelector,
    state => state?.recommended_training
)

export const customerRecommendedNutrition = createSelector(
    prStateSelector,
    state => state?.recommended_nutrition
)

export const customerRecommendedCards = createSelector(
    prStateSelector,
    state => state?.recommended_cards
)

export const customerLoginPendingSelector = createSelector(
    prStateSelector,
    state => state?.login_pending
)

export const customerRegistrationPendingSelector = createSelector(
    prStateSelector,
    state => state?.registration_pending
)


