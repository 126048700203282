import React from "react";

import HeaderMain from "./Header";
import Menu from "./Menu";

import Footer from "../common/Footer";
import styles from '@/public/styles/common/content.module.scss'

function LayoutCustomer({children}) {
    let Header = <HeaderMain/>;
    let FooterMain = <Footer/>;

    return (
        <div className={styles.layout}>
            {Header}
            <Menu/>
            {children}
            {FooterMain}
        </div>
    );
}

export default LayoutCustomer;