export const actionTypes = {
  SET_BLOG_POSTS: 'SET_BLOG_POSTS',
  SET_BLOG_CATEGORIES: 'SET_BLOG_CATEGORIES',
  SET_BLOG_CATEGORY: 'SET_BLOG_CATEGORY',
  SET_BLOG_CATEGORY_CURRENT_PAGE: 'SET_BLOG_CATEGORY_CURRENT_PAGE',
  SET_BLOG_TOTAL_COUNT: 'SET_BLOG_TOTAL_COUNT',
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY'
}

export const setBlogCategories = (payload) => ({
  type: actionTypes.SET_BLOG_CATEGORIES,
  payload
})

export const setBlogCategory = (payload) => ({
  type: actionTypes.SET_BLOG_CATEGORY,
  payload
})

export const setBlogPosts = (payload) => ({
  type: actionTypes.SET_BLOG_POSTS,
  payload
})

export const setCurrentPage = (payload) => ({
  type: actionTypes.SET_BLOG_CATEGORY_CURRENT_PAGE,
  payload
})

export const setTotalCount = (payload) => ({
  type: actionTypes.SET_BLOG_TOTAL_COUNT,
  payload
})

export const setSearchQuery = (payload) => ({
  type: actionTypes.SET_SEARCH_QUERY,
  payload
})