import {actionTypes} from './action'

const initialState = {
    data: null,
    login_pending: false,
    registration_pending: false,
    recommended_articles: [],
    recommended_training: [],
    recommended_nutrition: [],
    recommended_cards: [],
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_CUSTOMER_DATA:
            return {
                ...state,
                data: action.payload || null
            }

        case actionTypes.SET_RECOMMENDATIONS:
            return {
                ...state,
                recommendations: action.payload || null
            }
        case actionTypes.SET_RECOMMENDED_ARTICLES:
            return {
                ...state,
                recommended_articles: action.payload || null
            }
        case actionTypes.SET_RECOMMENDED_TRAINING:
            return {
                ...state,
                recommended_training: action.payload || null
            }
        case actionTypes.SET_RECOMMENDED_NUTRITION:
            return {
                ...state,
                recommended_nutrition: action.payload || null
            }
        case actionTypes.SET_RECOMMENDED_CARDS:
            return {
                ...state,
                recommended_cards: action.payload || null
            }
        case actionTypes.LOAD_CUSTOMER_DATA:
            return {
                ...state,
                loadCustomer: false
            }

        case actionTypes.CUSTOMER_LOGIN_PENDING:
            return {
                ...state,
                login_pending: action.payload
            }
        case actionTypes.CUSTOMER_REGISTER_PENDING:
            return {
                ...state,
                registration_pending: action.payload
            }

        default:
            return state
    }
}
