import { actionTypes } from './action'

const initialState = {
  ssr_host: '',
  ssr_request_url: '',
  is_mobile: false,
  user_agent_bot: false,
  menu_active_url: false,
}

export default function reducer(state = initialState, action) {
  //console.log(action)
  switch (action.type) {
    case actionTypes.TOGGLE_IS_MOBILE_DEVICE:
      return {
        ...state,
        is_mobile: action.payload
      }

    case actionTypes.TOGGLE_IS_USER_AGENT_BOT:
      return {
        ...state,
        user_agent_bot: action.payload
      }
    
    case actionTypes.SET_SSR_HOST:
      return {
        ...state,
        ssr_host: action.payload
      }

    case actionTypes.SET_MENU_ACTIVE_URL:
      return {
        ...state,
        menu_active_url: action.payload
      }

    case actionTypes.SET_SSR_REQUEST_URL:
      return {
        ...state,
        ssr_request_url: action.payload.replace(/\/$/, '')
      }

    default:
      return state
  }
}
