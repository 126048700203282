import React from "react";
import TopBar from "@/components/common/header/TopBar";
import homeStyles from '@/public/styles/pages/home/header.module.scss'
import DownloadMobileAppNotice from "@/components/common/DownloadMobileAppNotice";

const Header = () => {
    return (
        <div>
            <DownloadMobileAppNotice />

            <div className={homeStyles.header}>
                <TopBar/>
                <div className={homeStyles.welcomeBlock}>
                    <div className={homeStyles.welcomeText}>
                        <div className={homeStyles.welcomePreTitle}>Добро пожаловать в</div>
                        <img className={homeStyles.logoBig} src={'/images-local/logo_big.png'} />
                    </div>
                </div>
                <img className={homeStyles.ruka} src={'/images-local/ruka.png'} />
            </div>
        </div>
    );
}
export default Header;