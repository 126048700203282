import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {parseCookies, setCookie} from 'nookies'
import {customerDataSelector} from "@/store/customer/selector";
import {fetchCustomerData} from "@/store/customer/action";
import Modal from 'react-modal';
Modal.setAppElement("#__next")
import styles from "@/public/styles/common/popup-left.module.scss";
import Link from 'next/link'
import {isUserDeviceMobileSelector} from "@/store/core/selector";

declare global {
    interface Window {
        ym: any;
    }
}

export default function InitialData(props) {
    let customer = useSelector(customerDataSelector)
    const isMobile = useSelector(isUserDeviceMobileSelector)
    let dispatch = useDispatch()
    let cookies = parseCookies()

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalViewed, setModalViewed] = React.useState(false);

    useEffect(() => {
        if (!customer && cookies.accessToken) {
            dispatch(fetchCustomerData())
        }
    })

    useEffect(() => {
        const eventListener = (event) => {
            if (window.ym) {
                window?.ym(86996338, 'reachGoal', 'popup_show_exit')
            }
            openModel()
        }

        setTimeout(function () {
            openModel()
        }, 30000)

        const openModel = () => {
            cookies = parseCookies()
            if (!!!cookies.closePopup && !modalViewed) {
                modalShow()
                setModalViewed(true)

                if (window.ym) {
                    window?.ym(86996338, 'reachGoal', 'popup_show')
                }
                setCookie(null, 'closePopup', '1',{
                    maxAge: 86400,
                    path: '/',
                })
            }
        }

         document.addEventListener('mouseleave', eventListener)

        const scrollListener = (event) => {
            let element = document.querySelector('#toggle-visible');
            if (element) {
                let position = element.getBoundingClientRect();

                if(position.top >= 0 && position.bottom + 250 <= window.innerHeight) {
                    if (window.ym) {
                        window?.ym(86996338, 'reachGoal', 'popup_show_scroll')
                    }
                    openModel()
                }
            }
        }

        if (isMobile) {
             document.addEventListener('scroll', scrollListener)
        }

        document.addEventListener('copy', function () {
            let text = window.getSelection();
            let newText = text + ' - https://wayout.fitness'
            let body_element = document.getElementsByTagName('body')[0];
            let addDiv = document.createElement('div');

            addDiv.style.position='absolute';
            addDiv.style.left='-99999px';
            body_element.appendChild(addDiv);
            addDiv.innerHTML = newText;

            text.selectAllChildren(addDiv);

            if (window.ym) {
                window.ym(86996338, 'reachGoal', 'copy_text')
            }

            window.setTimeout(function() {
                body_element.removeChild(addDiv);
            },0);
        })
    }, [])

    function modalShow() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                contentLabel="left"
                className={styles.content}
            >
                <span className={styles.close} onClick={() => {
                    closeModal()
                }}> </span>
                <div className={styles.header}>
                    <span>Задай вопрос ИИ 🧠 </span>
                </div>
                <div className={styles.description}>
                    Не нашел ответ на свой вопрос? <br />
                    Мы подключили искусственный интеллект - современный ChatGPT, будь в тренде получай самую передовую информацию, собранную невероятно умной технологией 🔥
                </div>
                <div className={styles.buttons}>
                    <Link href={'/questions'}><a className={styles.buttonLeft} onClick={() => {
                        if (window.ym) {
                            window.ym(86996338, 'reachGoal', 'q_chatgpt')
                        }
                        closeModal()
                    }}><span>Задать&nbsp;</span>вопрос ИИ</a></Link>
                    <Link href={'/questions'}><a className={styles.buttonRight} onClick={() => {
                        if (window.ym) {
                            window.ym(86996338, 'reachGoal', 'q_people')
                        }
                        closeModal()
                    }}><span>Задать&nbsp;</span>вопрос человеку</a></Link>
                </div>
            </Modal>
        </>
    )
}
