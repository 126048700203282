import React from 'react'
import Link from 'next/link'
import styles from "@/public/styles/pages/customer/account/menu.module.scss";
import cx from "classnames";
import {useSelector} from "react-redux";
import {menuActiveUrl} from "@/store/core/selector";


export default function Menu() {
    const selectedMenu = useSelector(menuActiveUrl)

    const menu = [
        {
            _id: 1,
            name: 'Рекомендации',
            urlKey: '/customer/recommendations',
        },
        {
            _id: 2,
            name: 'Мои данные',
            urlKey: '/customer/profile',
        },
        {
            _id: 3,
            name: 'Контроль измерений',
            urlKey: '/customer/params',
        },
        {
            _id: 4,
            name: 'Мои результаты',
            urlKey: '/customer/results',
            class: styles.selected
        },/*
        {
            _id: 5,
            name: 'Календарь',
            urlKey: '/customer/calendar'
        },*/
        {
            _id: 6,
            name: 'План питания',
            urlKey: '/customer/nutrition'
        },
        {
            _id: 7,
            name: 'Мои тренировки',
            urlKey: '/customer/training'
        }
    ]

    return (
        <div className={styles.contained}>
            <div className={styles.menu}>
                {menu?.map((item) => {
                        return (
                            <Link key={item._id} href={item.urlKey}><a className={cx({[styles.selected]: item.urlKey == selectedMenu} )}>{item.name}</a></Link>
                        );
                    }
                )}
            </div>
        </div>
    )
}
