import {actionTypes} from './action'

const initialState = {
    posts: null,
    categories: null,
    current_page: null,
    total_count: null,
    current_category: null,
    search_query: ''
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_BLOG_CATEGORIES:
            return {
                ...state,
                categories: action.payload || []
            }

        case actionTypes.SET_BLOG_CATEGORY:
            return {
                ...state,
                current_category: action.payload || null
            }
        case actionTypes.SET_BLOG_POSTS:
            return {
                ...state,
                posts: action.payload || null
            }
        case actionTypes.SET_BLOG_CATEGORY_CURRENT_PAGE:
            return {
                ...state,
                current_page: action.payload || null
            }
        case actionTypes.SET_BLOG_TOTAL_COUNT:
            return {
                ...state,
                total_count: action.payload || null
            }
        case actionTypes.SET_SEARCH_QUERY:
            return {
                ...state,
                search_query: action.payload || null
            }

        default:
            return state
    }
}
