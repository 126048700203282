import React from "react";
import styles from '@/public/styles/common/footer.module.scss'
import Link from 'next/link'


const Footer = () => (
    <footer className={styles.footer}>
        <div className={styles.footerContainer}>
            <div className={styles.footerLeft}>
                <div className={styles.copyright}>© {(new Date().getFullYear())} Wayout Fitness</div>
                { <div className={styles.menu}>
                    <ul>
                        <li><Link key={'agreement'} prefetch={false} href="https://wayout.fitness/page/agreement">Согласие на обработку персональных данных</Link></li>
                    </ul>
                </div> }
            </div>
            <div className={styles.footerRight}>
                <a href="mailto:info@wayout.fitness">info@wayout.fitness</a>
            </div>
        </div>
    </footer>
);

export default Footer;