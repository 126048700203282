import React from "react";
import TopBar from "./header/TopBar";
import DownloadMobileAppNotice from "@/components/common/DownloadMobileAppNotice";

const Header = () => (
    <>
        <DownloadMobileAppNotice />
        <TopBar/>
    </>
);

export default Header;