import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import mainStyles from "@/public/styles/common/app-download.module.scss";

export default function DownloadMobileAppNotice() {
    const [show, setShow] = useState(false);
    const [isIphone, setIsIphone] = useState(false);
    const [link, setLink] = useState('');

    useEffect(() => {
        let isIphone = /^(iPhone|iPad|iPod|iPhone Simulator|iPad Simulator|iPod Simulator)/.test(navigator.platform);
        setIsIphone(isIphone);

        let href = `${isIphone
            ? process?.env?.NEXT_PUBLIC_APP_STORE_LINK
            : process?.env?.NEXT_PUBLIC_GOOGLE_PLAY_LINK}`

        setLink(href)

        setShow(true);

        let interval = setInterval(() => {
            if (window.ym) {
                if (window.innerWidth < 1024) {
                    window?.ym(86996338, 'reachGoal', 'show_app_link')
                }
                clearInterval(interval);
            }
        }, 500);


    }, []);

    if (!show) {
        return null;
    }

    return (
        <div className={mainStyles.bar}>
            <div className="flex align-center">
                <div
                    className={mainStyles.close}
                    onClick={() => setShow(false)}>
                </div>

                <a className="logo-text flex align-center" href={link}>
                    <div className={mainStyles.logo}>
                        <img src={'/images-local/app-icon.png'}/>
                    </div>

                    <div className="text">
                        <div className={mainStyles.title}>
                            Худеем правильно
                        </div>

                        <p className={mainStyles.description}>
                            Лучшее приложение для<br/>долгосрочного похудения
                        </p>
                    </div>
                </a>

            </div>
            <a
                href={link}
                onClick={
                    () => {
                        if (window.ym) {
                            window?.ym(86996338, 'reachGoal', 'click_app_link')

                            if (isIphone) {
                                window?.ym(86996338, 'reachGoal', 'click_app_link_ios')
                            } else {
                                window?.ym(86996338, 'reachGoal', 'click_app_link_android')
                            }
                        }
                    }
                }
                className={mainStyles.button}>
                Скачать
            </a>

        </div>
    )
}
