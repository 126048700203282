import {actionTypes} from './action'

const initialState = {
    home_page: null
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_HOME_PAGE:
            return {
                ...state,
                home_page: action.payload || []
            }

        default:
            return state
    }
}
