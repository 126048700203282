import axios from 'axios'
import getConfig from 'next/config'
import {secureRequest} from '@/lib/utils/secureRequest'
import { store } from 'react-notifications-component';
import { parseCookies, setCookie, destroyCookie } from 'nookies'

const API_HOST = process.env.NEXT_PUBLIC_API_URL;

export const actionTypes = {
    SET_CUSTOMER_DATA: 'SET_CUSTOMER_DATA',
    LOAD_CUSTOMER_DATA: 'LOAD_CUSTOMER_DATA',
    CUSTOMER_LOGIN: 'CUSTOMER_LOGIN',
    CUSTOMER_LOGIN_PENDING: 'CUSTOMER_LOGIN_PENDING',
    CUSTOMER_REGISTER: 'CUSTOMER_REGISTER',
    CUSTOMER_REGISTER_PENDING: 'CUSTOMER_REGISTER_PENDING',

    SET_RECOMMENDATIONS: 'SET_RECOMMENDATIONS',
    SET_RECOMMENDED_TRAINING: 'SET_RECOMMENDED_TRAINING',
    SET_RECOMMENDED_NUTRITION: 'SET_RECOMMENDED_NUTRITION',
    SET_RECOMMENDED_ARTICLES: 'SET_RECOMMENDED_ARTICLES',
    SET_RECOMMENDED_CARDS: 'SET_RECOMMENDED_CARDS',
}

export const setRecommendations = (payload) => ({
    type: actionTypes.SET_RECOMMENDATIONS,
    payload
})


export const loadRecommendations = (ctx = null) => (dispatch) => {
    return secureRequest(`/api/customer/recommendations`, 'GET', null, ctx).then(
        res => {
            dispatch(setRecommendations(res.data))
        },
        err => {
            console.log(err)
        }
    )
}

export const setRecommendedArticles = (payload) => ({
    type: actionTypes.SET_RECOMMENDED_ARTICLES,
    payload
})


export const loadRecommendedArticles = (size = 6, ctx = null) => (dispatch) => {
    return secureRequest(`/api/customer/recommended/posts?page_size=${size}`, 'GET', null, ctx).then(
        res => {
            dispatch(setRecommendedArticles(res.data))
        },
        err => {
            console.log(err)
        }
    )
}

export const setRecommendedTraining = (payload) => ({
    type: actionTypes.SET_RECOMMENDED_TRAINING,
    payload
})

export const loadRecommendedTraining = (size= 6, ctx = null) => (dispatch) => {
    return secureRequest(`/api/customer/recommended/training-plans?page_size=${size}`, 'GET', null, ctx).then(
        res => {
            dispatch(setRecommendedTraining(res.data))
        },
        err => {
            console.log(err)
        }
    )
}

export const setRecommendedNutrition = (payload) => ({
    type: actionTypes.SET_RECOMMENDED_NUTRITION,
    payload
})

export const loadRecommendedNutrition = (size = 6, ctx = null) => (dispatch) => {
    return secureRequest(`/api/customer/recommended/nutrition-plans?page_size=${size}`, 'GET', null, ctx).then(
        res => {
            dispatch(setRecommendedNutrition(res.data))
        },
        err => {
            console.log(err)
        }
    )
}

export const setRecommendedCards = (payload) => ({
    type: actionTypes.SET_RECOMMENDED_CARDS,
    payload
})

export const loadRecommendedCards = (size = 6, ctx = null) => (dispatch) => {
    return secureRequest(`/api/customer/recommended/cards`, 'GET', null, ctx).then(
        res => {
            dispatch(setRecommendedCards(res.data))
        },
        err => {
            console.log(err)
        }
    )
}

export const setCustomerData = (payload) => ({
    type: actionTypes.SET_CUSTOMER_DATA,
    payload
})

export const fetchCustomerData = (ctx = null) => (dispatch) => {
    console.log('dispatch')
    return secureRequest(`/api/customer/data`, 'GET', null, ctx).then(
        res => {
            console.log(res)
            dispatch(setCustomerData(res.data))
        },
        err => {
            console.log(err)
        }
    )
}

export const customerLoginPending = (payload) => ({
    type: actionTypes.CUSTOMER_LOGIN_PENDING,
    payload
})

export const customerLogin = (login, password) => (dispatch) => {
    dispatch(customerLoginPending(true))
    return axios.post(`${API_HOST}/api/customer/login`, {
        login: login,
        password: password
    }).then(res => {
            setCookie(null, 'accessToken', res.data.accessToken, {
                maxAge: 30 * 24 * 60 * 60,
                path: '/',
            })
            setCookie(null, 'refreshToken', res.data.refreshToken, {
                maxAge: 30 * 24 * 60 * 60,
                path: '/',
            })

            dispatch(fetchCustomerData())
            dispatch(customerLoginPending(false))
        },
        error => {
            store.addNotification({
                title: 'Вход',
                message: error.response.data.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: false
                }
            });
            dispatch(customerLoginPending(false))
        }
    )
}

export const customerRegistrationPending = (payload) => ({
    type: actionTypes.CUSTOMER_REGISTER_PENDING,
    payload
})

export const customerRegister = (customer) => (dispatch) => {
    dispatch(customerRegistrationPending(true))

    return axios.post(`${API_HOST}/api/customer/register`, customer).then(res => {
            setCookie(null, 'accessToken', res.data.accessToken, {
                maxAge: 30 * 24 * 60 * 60,
                path: '/',
            })
            setCookie(null, 'refreshToken', res.data.refreshToken, {
                maxAge: 30 * 24 * 60 * 60,
                path: '/',
            })

            dispatch(fetchCustomerData())
            dispatch(customerRegistrationPending(false))
        },
        error => {
            store.addNotification({
                title: 'Вход',
                message: error.response.data.message,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: false
                }
            });
            dispatch(customerRegistrationPending(false))
        }
    )
}

export const customerUpdate = (customer) => (dispatch) => {
    secureRequest(`/api/customer/data`,'POST', customer).then(res => {
            dispatch(fetchCustomerData())
        },
        error => {
            store.addNotification({
                title: 'Профиль',
                message: error.response.data.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: false
                }
            });
        }
    )
}

export const customerLogout = () => (dispatch) => {
    return secureRequest(`/api/customer/logout`, 'GET').then(res => {
            destroyCookie(null,'accessToken', {
                path: '/',
            });
            destroyCookie(null,'refreshToken', {
                path: '/',
            });
            dispatch(setCustomerData(null))

            store.addNotification({
                title: 'Аккаунт',
                message: 'Вы вышли из аккаунта',
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: false
                }
            });
        },
        error => {
            store.addNotification({
                title: 'Вход',
                message: error.response.data.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: false
                }
            });
        }
    )
}

