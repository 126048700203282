import styles from '@/public/styles/pages/customer/account/header.module.scss'
import Link from 'next/link'
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import MobileMenu from "./MobileMenu";
import {action as toggleMenu} from 'redux-burger-menu';
import Logout from "@/components/customer/Logout";
import cx from 'classnames';
import {menuActiveUrl} from "@/store/core/selector";

const Navbar = () => {
    const dispatch = useDispatch();
    const selectedMenu = useSelector(menuActiveUrl)
    let isOpen = true

    let menuItems = [
        /*{
            _id: 1,
            name: 'Подписка',
            urlKey: '/customer/subscription',
        },*/
        {
            _id: 2,
            name: 'Полезные статьи',
            urlKey: '/',
        },
        {
            _id: 2,
            name: 'Сообщество',
            urlKey: '/questions',
        },
        {
            _id: 3,
            name: 'Калькуляторы',
            urlKey: '/calculators',
        },
        {
            _id: 4,
            name: 'Упражнения',
            urlKey: '/exercises',
        },
        {
            _id: 5,
            name: 'Личный кабинет',
            urlKey: '/customer/recommendations',
            class: styles.selected
        }
    ]

    let mobileMenuItems = [
        {
            _id: 1,
            name: 'Рекомендации',
            urlKey: '/customer/recommendations',
        },
        {
            _id: 2,
            name: 'Мои данные',
            urlKey: '/customer/profile',
        },
        {
            _id: 3,
            name: 'Контроль измерений',
            urlKey: '/customer/params',
        },
        {
            _id: 4,
            name: 'Мои результаты',
            urlKey: '/customer/results',
            class: styles.selected
        },/*
        {
            _id: 5,
            name: 'Календарь',
            urlKey: '/customer/calendar'
        },*/
        {
            _id: 6,
            name: 'План питания',
            urlKey: '/customer/nutrition'
        },
        {
            _id: 7,
            name: 'Мои тренировки',
            urlKey: '/customer/training'
        },
        {
            _id: 8,
            name: 'Сообщество',
            urlKey: '/questions',
        },
        {
            _id: 9,
            name: 'Калькуляторы',
            urlKey: '/calculators',
        },
        {
            _id: 10,
            name: 'Упражнения',
            urlKey: '/exercises',
        },
    ]


    let logoImage = '/images-local/logo.svg';
    let searchIcon = <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="m23.2 23.2-6.4-6.4M10.4 20a9.6 9.6 0 1 1 0-19.2 9.6 9.6 0 0 1 0 19.2Z" stroke="#fff"/>
    </svg>;
    let mobileMenuIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 4H24M4.8 12H19.2M8 20H16" stroke="white"/>
    </svg>;
    let menuClass = styles.menu

    return (
        <>
            <MobileMenu className={styles.mobileMenuMain} itemListClassName={"customer-mobile-menu"}
                        customBurgerIcon={false} disableAutoFocus width={'100%'}>
                <div className={styles.menuWrapper}>
                    <div className={styles.main}>
                        <div className={styles.menuMain}>
                            {mobileMenuItems?.map((item) => {
                                    return (
                                        <div key={item._id} className={cx({[styles.selected]: item.urlKey == selectedMenu} )}><Link
                                            href={item.urlKey}><a>{item.name}</a></Link></div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                    <div className={styles.additional}>
                        <div className={styles.menuLogout}>
                            <Logout/>
                        </div>
                        <div className={styles.backToBlog}><Link prefetch={false} href={'/'}><a>Вернуться в блог</a></Link></div>
                    </div>
                </div>
            </MobileMenu>
            <div className={styles.topBar}>
                <div className={styles.leftWrapper}>
                    <div className={styles.mobileMenu} onClick={async () => {
                        dispatch(toggleMenu(isOpen));
                    }}>
                        {mobileMenuIcon}
                    </div>
                    <Link href={'/customer/recommendations'}>
                        <a><img
                            src={logoImage}
                            alt="App logo"
                            className={styles.logo}
                        /></a>
                    </Link>
                </div>
                <div className={styles.rightBar}>
                    <ul className={menuClass}>
                        {menuItems?.map((item) => {
                                return (
                                    <li className={item.class} key={item._id}>
                                        <Link href={item.urlKey}><a>{item.name}</a></Link>
                                    </li>
                                );
                            }
                        )}
                    </ul>

                    <Link href="/search">
                        <div className={styles.searchIcon}>
                            {searchIcon}
                        </div>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default Navbar;

