import { createStore, applyMiddleware, combineReducers } from 'redux'
import { HYDRATE, createWrapper } from 'next-redux-wrapper'
import thunkMiddleware from 'redux-thunk'
import {reducer as burgerMenu} from 'redux-burger-menu';

import core from './core/reducer'
import blog from './blog/reducer'
import page from './page/reducer'
import customer from './customer/reducer'

const bindMiddleware = (middleware) => {
    if (process.env.NODE_ENV !== 'production') {
        const { composeWithDevTools } = require('redux-devtools-extension')
        return composeWithDevTools(applyMiddleware(...middleware))
    }
    return applyMiddleware(...middleware)
}

const combinedReducer = combineReducers({
    burgerMenu,
    blog,
    core,
    page,
    customer
})

const reducer = (state, action) => {
    if (action.type === HYDRATE) {
        const nextState = {
            ...state, // use previous state
            ...action.payload, // apply delta from hydration
        }

        if(state?.blog?.categories) nextState.blog.categories = state.blog.categories
        if(state?.blog?.current_category) nextState.blog.current_category = state.blog.current_category
        if(state?.core?.menu_active_url) nextState.core.menu_active_url = state.core.menu_active_url
        if(state?.page?.home_page) nextState.page.home_page = state.page.home_page
        if(state?.customer?.data) nextState.customer.data = state.customer.data
        if(state?.customer?.recommendations) nextState.customer.recommendations = state.customer.recommendations
        if(state?.customer?.recommended_articles) nextState.customer.recommended_articles = state.customer.recommended_articles
        if(state?.customer?.recommended_training) nextState.customer.recommended_training = state.customer.recommended_training
        if(state?.customer?.recommended_nutrition) nextState.customer.recommended_nutrition = state.customer.recommended_nutrition
        if(state?.customer?.loginPending) nextState.customer.loginPending = state.customer.loginPending
        if(state?.customer?.registrationPending) nextState.customer.registrationPending = state.customer.registrationPending

        return {...nextState}
    } else {
        return combinedReducer(state, action)
    }
}

const middlewares = [
    thunkMiddleware
]

const initStore = () => {
    return createStore(
        reducer,
        bindMiddleware(middlewares)
    )
}

export const wrapper = createWrapper(initStore)