import styles from '@/public/styles/common/header.module.scss'
import stylesHome from '@/public/styles/pages/home/header.module.scss'
import {useRouter} from 'next/router'
import Link from 'next/link'
import React from "react";
import cx from 'classnames';
import {useDispatch, useSelector} from "react-redux";
import {blogCategoriesSelector, currentBlogCategorySelector} from "store/blog/selector";
import {customerDataSelector} from "store/customer/selector";
import MobileMenu from "./MobileMenu";
import {action as toggleMenu} from 'redux-burger-menu';
import Logout from "@/components/customer/Logout";


function getTopParent(parent, categories) {
    let current = false;
    categories.forEach(item => {
        if (item._id == parent) {
            if ('parent' in item) {
                return getTopParent(item.parent, categories)
            }

            current = item;
        }
    });

    return current;
}

const Navbar = () => {
    const dispatch = useDispatch();
    let isOpen = true

    let customer = useSelector(customerDataSelector)

    let savedCategories = useSelector(blogCategoriesSelector)
    let categories = JSON.parse(JSON.stringify(savedCategories))
    const blogCategories = categories.filter(item => {
        return !('parent' in item)
    })

    const currentCategory = useSelector(currentBlogCategorySelector)
    let currentItemId = '';
    let selectedMenuItem = currentCategory;

    if (currentCategory) {
        if (currentCategory.parent) {
            selectedMenuItem = getTopParent(currentCategory.parent, categories)
        }
        currentItemId = selectedMenuItem._id
    }

    let useful = blogCategories.map(item => {
        if (!item?.prepared) {
            item.urlKey = `/blog/category/${item.urlKey}`
            if (item._id === currentItemId) {
                item.class = styles.selected
            } else {
                item.class = null;
            }

            item.prepared = true
        }

        return item;
    });

    useful.push({
        urlKey: '/food/calories',
        name: 'Калорийность продуктов',
        class: null
    })

    const menuItems = [
        {
            _id: 'questions',
            urlKey: '/questions',
            name: 'Сообщество',
            class: null
        },
        {
            _id: 'blog',
            urlKey: '/',
            name: 'Полезные статьи',
            children: useful
        },
        {
            _id: 'exercises',
            urlKey: '/exercises',
            name: 'Упражнения',
            class: null
        },
        {
            _id: 'calculators',
            urlKey: '/calculators',
            name: 'Калькуляторы',
            class: null
        },
    ]

    const router = useRouter()

    let logoImage = '/images-local/logo-new.png';
    let searchIcon = <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="m23.2 23.2-6.4-6.4M10.4 20a9.6 9.6 0 1 1 0-19.2 9.6 9.6 0 0 1 0 19.2Z" stroke="#fff"/>
    </svg>;
    let mobileMenuIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 4H24M4.8 12H19.2M8 20H16" stroke="white"/>
    </svg>;

    let menuClass = cx(stylesHome.menu, styles.menu)
    let loginClass = cx(stylesHome.loginBtn, styles.loginBtn)
    let customerClass = cx(stylesHome.userButton, styles.userButton)

    return (
        <>
            <MobileMenu className={styles.mobileMenuMain} itemListClassName={"customer-mobile-menu"}
                        customBurgerIcon={false} noTransition width={'100%'}>
                <div className={styles.menuWrapper}>
                    <div className={styles.main}>
                        <div className={styles.menuMain}>
                            {menuItems?.map((item) => {
                                    return (
                                        <div key={item._id}>
                                            <Link href={`${item.urlKey}`}><a onClick={() => {
                                                dispatch(toggleMenu(false));
                                            }}>{item.name}</a></Link>
                                            {
                                                item?.children != null &&
                                                <ul className={styles.children}>
                                                    {item.children.map((item) => {
                                                        return (
                                                            <li key={item._id}>
                                                                <Link
                                                                    href={`${item.urlKey}`}><a onClick={() => {
                                                                    dispatch(toggleMenu(false));
                                                                }}>{item.name}</a></Link>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            }
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                    <div className={styles.additional}>
                        {customer?._id &&
                            <div className={styles.menuLogout}>
                                <Logout/>
                            </div>
                        }
                    </div>
                </div>
            </MobileMenu>
            <div className={styles.topBar}>
                <div className={styles.leftWrapper}>
                    <div className={styles.mobileMenu} onClick={async () => {
                        dispatch(toggleMenu(isOpen));
                    }}>
                        {mobileMenuIcon}
                    </div>
                    <Link prefetch={false} href={'/'} as="/">
                        <a><img
                            src={logoImage}
                            alt="Wayout Fitness logo"
                            className={cx(styles.logo, {[stylesHome.logo]: router.asPath == '/'})}
                        /></a>
                    </Link>
                </div>

                <ul className={menuClass}>
                    {menuItems?.map((item) => {
                            return (
                                <li className={item.class} key={item._id}>
                                    <Link prefetch={false} href={`${item.urlKey}`}><a>{item.name}</a></Link>
                                    {
                                        item?.children != null &&
                                        <ul className={styles.children}>
                                            {item.children.map((item) => {
                                                return (
                                                    <li key={item._id}>
                                                        <Link prefetch={false}
                                                              href={`${item.urlKey}`}><a>{item.name}</a></Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    }
                                </li>
                            );
                        }
                    )}
                </ul>

                <div className={styles.rightBar}>
                    <Link prefetch={false} href="/search" as="/search">
                        <div className={styles.searchIcon}>
                            {searchIcon}
                        </div>
                    </Link>
                    {customer?._id && <Link href="/customer/recommendations">
                        <div className={customerClass}>

                        </div>
                    </Link>}
                    {!customer?._id &&
                        <Link prefetch={false} href="/customer/login">
                            <div className={loginClass}>
                                Войти
                            </div>
                        </Link>
                    }
                </div>
            </div>
        </>
    );
}

export default Navbar;

