export const actionTypes = {
  TOGGLE_IS_MOBILE_DEVICE: 'TOGGLE_IS_MOBILE_DEVICE',
  TOGGLE_IS_USER_AGENT_BOT: 'TOGGLE_IS_USER_AGENT_BOT',
  SET_SSR_HOST: 'SET_SSR_HOST',
  SET_SSR_REQUEST_URL: 'SET_SSR_REQUEST_URL',
  SET_MENU_ACTIVE_URL: 'SET_MENU_ACTIVE_URL',
}

export const toggleIsMobileDevice = (payload: boolean) => ({
  type: actionTypes.TOGGLE_IS_MOBILE_DEVICE,
  payload
})

export const toggleIsUserAgentBot = (payload: boolean) => ({
  type: actionTypes.TOGGLE_IS_USER_AGENT_BOT,
  payload
})

export const setSsrHost = (payload) => ({
  type: actionTypes.SET_SSR_HOST,
  payload
})

export const setSsrRequestUrl = (payload) => ({
  type: actionTypes.SET_SSR_REQUEST_URL,
  payload
})

export const setMenuActiveUrl = (payload) => ({
  type: actionTypes.SET_MENU_ACTIVE_URL,
  payload
})

