import axios from 'axios'
import getCacheInstance from '@/lib/cache'

const lruCache = getCacheInstance()
const API_HOST = process.env.NEXT_PUBLIC_API_URL;

let day = 24 * 1000 * 60

export const request = async (path, method, data = null, cache = false, cacheTime = day) => {
    let result = null;
    try {
        if (cache) {
            let cachedData = lruCache.get(path)
            if (cachedData) {
                return JSON.parse(cachedData);
            }
        }

        if (method == 'GET') {
            result = await axios.get(`${API_HOST}${path}`)
        } else if (method == 'POST') {
            result = await axios.post(`${API_HOST}${path}`, data)
        }

        if (cache && result?.data) {
            lruCache.set(path, JSON.stringify(result?.data), cacheTime)
        }

        return result?.data;
    } catch (error) {
        console.log('!error!', error)

        throw new Error('request failed')
    }
}

export const sendRequest = async (path, method, data = null) => {
    if (method == 'GET') {
        return  axios.get(`${API_HOST}${path}`)
    } else if (method == 'POST') {
        return axios.post(`${API_HOST}${path}`, data)
    }
}