const LRU = require("lru-cache")

export default function getCacheInstance() {
    if (!global.lruCache) {
        global.lruCache = new LRU()
    } else {
    }

    return global.lruCache

}