import React from 'react'

export default function GtmBody(props) {
    const {gtmId} = props

    return (
        <>
            <iframe
                id="gtm_body_iframe"
                src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
                height="0"
                width="0"
                style={{display: 'none'}}
            ></iframe>
        </>
    )
}
