import React from 'react'
import {useRouter} from 'next/router'
import {useDispatch} from 'react-redux'
import {customerLogout} from '@/store/customer/action'
import inputStyles from "@/public/styles/components/inputs.module.scss";


export default function Logout() {
    const dispatch = useDispatch();
    const router = useRouter();
    const logout = async () => {
        await dispatch(customerLogout());
        await router.push('/')
    }


    return (
        <>
            <div className={inputStyles.logoutMenu} onClick={()=>{logout()}}>Выйти</div>
        </>
    )
}
