import axios from 'axios'
import { store } from 'react-notifications-component';
import { parseCookies, setCookie, destroyCookie } from 'nookies'
const API_HOST = process.env.NEXT_PUBLIC_API_URL;

export const secureRequest = async (path, method, data = null, ctx = null, accessToken = null, refresh = false) => {
    let cookies = parseCookies(ctx);

    let token = cookies.accessToken;
    if (accessToken) {
        token = accessToken;
    }

    let result = null;
    try {
        if (method == 'GET') {
            result = await axios.get(`${API_HOST}${path}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        } else if (method == 'POST') {
            result = await axios.post(`${API_HOST}${path}`, data, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
        }

        return result;
    } catch (error) {
        if (!refresh && error.response.data.statusCode == 401) {
            let accessToken = await refreshToken(ctx)
            if (accessToken) {
                return await secureRequest(path, method, data, ctx, accessToken,true)
            } else {
                return { data: null };
            }
        } else {
            store.addNotification({
                title: 'Ошибка',
                message: error.response.data.message || '',
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: false
                }
            });
        }
    }
}

export const refreshToken = async (ctx = null) => {
    try {
        let cookies = parseCookies(ctx);
        let refreshToken = cookies.refreshToken;
        let resp = await axios.post(`${API_HOST}/api/customer/refresh`, {refreshToken: refreshToken})

        setCookie(ctx, 'accessToken', resp.data.accessToken, {
            maxAge: 30 * 24 * 60 * 60,
            path: '/',
        })
        setCookie(ctx, 'refreshToken', resp.data.refreshToken, {
            maxAge: 30 * 24 * 60 * 60,
            path: '/',
        })

        return resp.data.accessToken;
    } catch (e) {
        console.log('refresh error')
        destroyCookie(ctx,'accessToken', {path: '/'});
        destroyCookie(ctx,'refreshToken', {path: '/'});

        destroyCookie({},'accessToken', {path: '/'});
        destroyCookie({},'refreshToken', {path: '/'});

        return false;
    }
}