import { createSelector } from 'reselect'

const prStateSelector = state => state.blog

export const blogCategoriesSelector = createSelector(
  prStateSelector,
  state => state.categories
)

export const blogPostsSelector = createSelector(
    prStateSelector,
    state => state.posts
)

export const blogCurrentPageSelector = createSelector(
    prStateSelector,
    state => state.current_page
)

export const totalCountSelector = createSelector(
    prStateSelector,
    state => state.total_count
)

export const currentBlogCategorySelector = createSelector(
  prStateSelector,
  state => state.current_category
)

export const searchQuerySelector = createSelector(
  prStateSelector,
  state => state.search_query
)
