import { createSelector } from 'reselect'

const coreStateSelector = state => state.core

export const isUserDeviceMobileSelector = createSelector(
  coreStateSelector,
  state => state?.is_mobile
)

export const ssrHostSelector = createSelector(
  coreStateSelector,
  state => state?.ssr_host
)

export const ssrRequestUrlSelector = createSelector(
  coreStateSelector,
  state => state?.ssr_request_url
)


export const isUserAgentBotSelector = createSelector(
  coreStateSelector,
  state => state?.user_agent_bot
)

export const menuActiveUrl = createSelector(
  coreStateSelector,
  state => state?.menu_active_url
)
