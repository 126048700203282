import React from "react";
import {useRouter} from 'next/router'

import HeaderMain from "./Header";
import HomeHeader from "../home/Header";

import Footer from "./Footer";
import styles from '@/public/styles/common/content.module.scss'

function LayoutMain({children}) {
    const router = useRouter()
    let Header = <HeaderMain/>;
    let FooterMain = <Footer/>;
    if (router.asPath == '/') {
        Header = <HomeHeader/>;
    }

    return (
        <div className={styles.layout}>
            {Header}
            {children}
            {FooterMain}
        </div>
    );

}

export default LayoutMain;