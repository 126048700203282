import React, {useEffect, useState} from 'react'
import { useRouter } from "next/router";
import cx from "classnames";


export default function Loader() {
    const router = useRouter();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        router.events.on("routeChangeError", (e) => setLoading(true));
        router.events.on("routeChangeStart", (e) => setLoading(true));
        router.events.on("routeChangeComplete", (e) => setLoading(false));

        return () => {
            router.events.off("routeChangeError", (e) => setLoading(true));
            router.events.off("routeChangeStart", (e) => setLoading(true));
            router.events.off("routeChangeComplete", (e) => setLoading(false));
        };
    }, [router.events]);

    return (
        <>
            <div  style={{
                background: "white",
                position: "fixed",
                zIndex: 1,
                top: "0",
                right: "0",
                bottom: "0",
                left: "0",
                opacity: !loading ? "0" : "1",
                transition: "all .2s",
                visibility: !loading ? "hidden" : "visible",
            }}><span className={cx('css-loader')}></span></div>
        </>
    )
}
